import { downloadURL } from '@/components/utils/utils';
import { ConsultantData } from '@/types/consultant';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { CronExpression, parseExpression } from 'cron-parser';
import { addMonths, startOfMonth, endOfMonth, isSunday, addDays, isSaturday, isBefore, isAfter, isEqual, setHours, isValid  } from 'date-fns';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function firstLastName(fullName: string): string | null {
	fullName = fullName.trim() 
	const arrNames = fullName.split(' ')
	if(!arrNames[0].trim()) return null

	return [
		arrNames[0],
		arrNames.length > 1 ? arrNames[arrNames.length -1] : ''
	].join(' ')
}

export async function downloadFileFromLaravel(url: string, token: string, name: string) {
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": 'Bearer ' + token
			}
		})

		if(response.ok && response.status === 200) {
			const blob = await response.blob()
			const url = URL.createObjectURL(blob)
			downloadURL(url, name);
		}
		
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}

export function stringToDate(string: string) : Date {
    const stringParts: Array<string> = string.split("-");
    return new Date(parseInt(stringParts[0]), parseInt(stringParts[1]) - 1, parseInt(stringParts[2]))
}

export function dateToString(date: Date) : string {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
}

export function getCurrentMonth(): string {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    return month < 10 ? `0${month}` : `${month}`;
}

export function getCurrentYear(): string {
    const currentDate = new Date();
    return currentDate.getFullYear().toString();
}

export function dateToLaravelStyle(date: Date) {
	const addLeadingZero = (number: number) => (number < 10 ? `0${number}` : number);

	const year = date.getFullYear();
	const month = addLeadingZero(date.getUTCMonth() + 1);
	const day = addLeadingZero(date.getUTCDate());
	const hours = addLeadingZero(date.getUTCHours());
	const minutes = addLeadingZero(date.getUTCMinutes());
	const seconds = addLeadingZero(date.getUTCSeconds());

	const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

	return formattedDate;
}

export function dateToCronString(date: Date): string {
    const minute = date.getMinutes();
    const hour = date.getHours();
    const dayOfMonth = date.getDate();
    const month = date.getMonth() + 1;
    const dayOfWeek = '*';

    return `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
}

export function getDatesFromCron(cronString: string, referenceDate: Date, viewDate: Date, end_date?: string | null): Date[] {
	const dateTimeFormat = new Intl.DateTimeFormat();
	// Obtém as opções resolvidas do formato de data e hora
	const dateTimeOptions = dateTimeFormat.resolvedOptions();

	// O fuso horário do usuário está disponível em dateTimeOptions.timeZone
	const userTimeZone = dateTimeOptions.timeZone;
	const expression = parseExpression(cronString, {
        currentDate: referenceDate,	
        tz: userTimeZone,
    });

    const startDate = startOfMonth(viewDate);
    let endDate = endOfMonth(viewDate);
    if(end_date) {
        const date = new Date(end_date);
        if(isBefore(date, endDate)) endDate = date
    }

    const intervalDates: Date[] = [];
    let nextDate = expression.next();

    while (nextDate && nextDate.getTime() <= endDate.getTime()) {
        if (nextDate.getTime() >= startDate.getTime()) {
            intervalDates.push(nextDate.toDate());
        }
        nextDate = expression.next();
    }

    return intervalDates;
}

export function getRandomStringFromArray(strings: string[]): string {
    if (strings.length === 0) {
        throw new Error('Array vazio para pegar item aleatório');
    }

    const randomIndex = Math.floor(Math.random() * strings.length);
    return strings[randomIndex];
}


export function formatConsultantPhone(fullNumber: string) : string {
  return `(${fullNumber.slice(0,2)}) ${fullNumber.slice(2,-4)}-${fullNumber.slice(-4)}`
}

export function sortObjectByNumericValue(obj: Record<string, number>): Record<string, number> {
    const keyValueArray = Object.entries(obj);
    keyValueArray.sort((a, b) => a[1] - b[1]);
    const sortedObject: Record<string, number> = Object.fromEntries(keyValueArray);
    return sortedObject;
}

export function sortObjectWithSequence(obj: Record<string, number>): Record<string, number> {
    const keyValueArray = Object.entries(obj);
    keyValueArray.sort((a, b) => a[1] - b[1]);
    const sortedObject: Record<string, number> = {};
    keyValueArray.forEach((entry, index) => sortedObject[entry[0]] = index + 1);
    return sortedObject;
}

export const integerFormatter = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 0, minimumFractionDigits: 0 });




export function hasNameOrNickname(consultant: {
	SortableName: string;
	nickname: string | null;
}, search: string) {
	if(consultant.nickname?.toLowerCase()?.includes(search.toLowerCase())) return true;
	if(consultant.SortableName?.toLowerCase()?.includes(search.toLowerCase())) return true;
	return false;
}

export function getRandomInt(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function generateRandomNumberOfDigits(digits: number): number {
    const min = Math.pow(10, digits - 1);
    const max = Math.pow(10, digits) - 1; 
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

type MonthData = { name: string; date: string };
export function generatePreviousMonthsArray(numMonths: number, startDate?: Date): MonthData[] {
    const currentDate = startDate ?? new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const monthsArray: MonthData[] = [];

    for (let i = 2; i <= numMonths + 1; i++) {
        const previousMonthDate = new Date(currentYear, currentMonth - i, 1);
        const year = previousMonthDate.getFullYear();
        const month = String(previousMonthDate.getMonth() + 1).padStart(2, '0'); 
		const monthNumber = i - 1;
        const name = monthNumber > 1 ? `${monthNumber} meses atrás` : `${monthNumber} mês atrás`;
        const date = `${year}-${month}`;
        monthsArray.push({ name, date });
    }

    return monthsArray;
}

export function getCurrentDateFormatted(): string {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}


export function getNextUtilDay(data: Date): Date {
  let nextDate = data;
  while (isSaturday(nextDate) || isSunday(nextDate)) {
    nextDate = addDays(nextDate, 1);
  }

  return nextDate;
}

export function getDatesForFortnightly(startDate: Date, viewDate: Date, hour: number, end_date?: string | null): Date[] {
    // fim do mês referente a data que estamos visualizando
    let viewEndDate = endOfMonth(viewDate);
    // se a data de visualização for maior que a data que a tarefa tem seu termino então a data final é a data de termino
    if(end_date && isBefore(new Date(end_date), viewEndDate)) {
       viewEndDate = new Date(end_date) 
    }

    const intervalDates: Date[] = [];

    let currentDate = startDate; // vamos partir da data que a tarefa começa obviamente

    // se a data for anterior a data final ou for exatamente a data final então ela é uma tata valida
    while (isBefore(currentDate, viewEndDate) || isEqual(currentDate, viewEndDate)) {
        const validCurrentDate = getNextUtilDay(currentDate); // não pode ser sábado ou domingo então pegamos o próximo dia entre segunda e sexta
        intervalDates.push(validCurrentDate); // adicionamos então a data ao array 
        currentDate = addDays(validCurrentDate, 15); // e então a próxima data deve ser a anterior mais 15 dias
    }


    return intervalDates;
}

export function formatToMoney(number: number, options?: Intl.NumberFormatOptions) {
    return new Intl.NumberFormat('PT-BR', {
        style: 'currency',
        currency: 'BRL',
        ...options,
    }).format(number);
}

export function YMDToDateWithLocalTimezone(date: string) {
    date = `${date}T${new Date().toISOString().split('T')[1]}`;
    return new Date(date);
}