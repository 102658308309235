'use client'

import { createContext, useContext, useEffect } from 'react'
import React from 'react'
import Bugsnag, { Client } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { BugError } from '@/components/bug-error'
import dynamic from 'next/dynamic';

const BugsnagPerformanceBoot = dynamic(() => import('./bugsnag-performance-boot'), { ssr: false });

interface ContextProps {
	bugsnag: Client
}

const BugContext = createContext<ContextProps|undefined>(undefined)

const client = Bugsnag.start({
	apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '',
	plugins: [new BugsnagPluginReact()],
	enabledReleaseStages: [ 'production', 'development']
})

export default function BugProvider({
  children,
}: {
  children: React.ReactNode
}) {  

	const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

	if(ErrorBoundary === undefined) throw new Error('Bugsnag não carregou')

	return (
		<BugContext.Provider value={{ bugsnag: client }}>
			<ErrorBoundary FallbackComponent={BugError}>
				{children}
				<BugsnagPerformanceBoot />
			</ErrorBoundary>
		</BugContext.Provider>
	)
}

export const useBugsnag = () => {
  const context = useContext(BugContext)
  if (!context) {
    throw new Error('useBugsnag must be used within a BugProvider')
  }
  return context
}