import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfigFile from '@/tailwind.config.js'

export const tailwindConfig = resolveConfig(tailwindConfigFile) as any

export const getBreakpointValue = (value: string): number => {
  const screenValue = tailwindConfig.theme.screens[value]
  return +screenValue.slice(0, screenValue.indexOf('px'))
}

export const getBreakpoint = () => {
  let currentBreakpoint
  let biggestBreakpointValue = 0
  let windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  for (const breakpoint of Object.keys(tailwindConfig.theme.screens)) {
    const breakpointValue = getBreakpointValue(breakpoint)
    if (
      breakpointValue > biggestBreakpointValue &&
      windowWidth >= breakpointValue
    ) {
      biggestBreakpointValue = breakpointValue
      currentBreakpoint = breakpoint
    }
  }
  return currentBreakpoint
}

export const hexToRGB = (h: string): string => {
  let r = 0
  let g = 0
  let b = 0
  if (h.length === 4) {
    r = parseInt(`0x${h[1]}${h[1]}`)
    g = parseInt(`0x${h[2]}${h[2]}`)
    b = parseInt(`0x${h[3]}${h[3]}`)
  } else if (h.length === 7) {
    r = parseInt(`0x${h[1]}${h[2]}`)
    g = parseInt(`0x${h[3]}${h[4]}`)
    b = parseInt(`0x${h[5]}${h[6]}`)
  }
  return `${+r},${+g},${+b}`
}

export const formatValue = (value: number, maximumSignificantDigits: number = 3): string => Intl.NumberFormat('pt-BR', {
  //style: 'currency',
  //currency: 'USD',
  maximumSignificantDigits,
  notation: 'compact',
}).format(value)

export const formatThousands = (value: number): string => Intl.NumberFormat('pt-BR', {
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value)

export function formatarDataToDayMonthYear(data: Date): string {
    const day = data.getDate();
    const month = data.getMonth() + 1;
    const year = data.getFullYear();

    const formatedDay = day < 10 ? `0${day}` : day.toString();
    const formatedonth = month < 10 ? `0${month}` : month.toString();

    return `${formatedDay}/${formatedonth}/${year}`;
}

export function downloadURL(url: string, name: string) {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.download = name;
  link.click();
  document.body.removeChild(link);
}

export function randomFakeUUID(): string {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    
    return (
        s4() + s4() + '-' +
        s4() + '-' +
        s4() + '-' +
        s4() + '-' +
        s4() + s4() + s4()
    );
}

export function dateToYYYYMMDD(date: Date, separator?: string) {
  const formatter = new Intl.DateTimeFormat('pt-BR', {
		year: 'numeric', 
		month: '2-digit', 
		day: '2-digit',
		timeZone: 'UTC'
	});
  const formattedDate = formatter.formatToParts(date);
  const realSeparator = separator ?? '/';

  return `${formattedDate[4].value}${realSeparator}${formattedDate[2].value}${realSeparator}${formattedDate[0].value}`;
}
