import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/app/app-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/app/bug-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/node/app/app/css/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/app/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/components/auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/node/app/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
