const plugin = require('tailwindcss/plugin')

/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: ["class"],
  content: [
    './pages/**/*.{ts,tsx}',
    './components/**/*.{ts,tsx}',
    './app/**/*.{ts,tsx}',
    './src/**/*.{ts,tsx}',
  ],
  prefix: "",
  theme: {
  	extend: {
  		colors: {
  			border: 'hsl(var(--border))',
  			input: 'hsl(var(--input))',
  			background: 'hsl(var(--background))',
  			foreground: 'hsl(var(--foreground))',
  			primary: {
  				DEFAULT: 'hsl(var(--primary))',
  				foreground: 'hsl(var(--primary-foreground))'
  			},
  			secondary: {
  				DEFAULT: 'hsl(var(--secondary))',
  				foreground: 'hsl(var(--secondary-foreground))'
  			},
  			destructive: {
  				DEFAULT: 'hsl(var(--destructive))',
  				foreground: 'hsl(var(--destructive-foreground))'
  			},
  			muted: {
  				DEFAULT: 'hsl(var(--muted))',
  				foreground: 'hsl(var(--muted-foreground))'
  			},
  			accent: {
  				DEFAULT: 'hsl(var(--accent))',
  				foreground: 'hsl(var(--accent-foreground))'
  			},
  			popover: {
  				DEFAULT: 'hsl(var(--popover))',
  				foreground: 'hsl(var(--popover-foreground))'
  			},
  			card: {
  				DEFAULT: 'hsl(var(--card))',
  				foreground: 'hsl(var(--card-foreground))'
  			},
  			slate: {
  				'100': '#FEFBFC'
  			},
  			indigo: {
  				'25': '#FEFBFC',
  				'50': '#fbf4f7',
  				'100': '#f7ecf1',
  				'200': '#f1d9e5',
  				'300': '#e7bace',
  				'400': '#d78faf',
  				'500': '#c86c92',
  				'600': '#b7577a',
  				'700': '#993d5c',
  				'800': '#80344d',
  				'900': '#6b3043',
  				'950': '#401724'
  			}
  		},
  		borderRadius: {
  			lg: 'var(--radius)',
  			md: 'calc(var(--radius) - 2px)',
  			sm: 'calc(var(--radius) - 4px)'
  		},
  		keyframes: {
  			'accordion-down': {
  				from: {
  					height: '0'
  				},
  				to: {
  					height: 'var(--radix-accordion-content-height)'
  				}
  			},
  			'accordion-up': {
  				from: {
  					height: 'var(--radix-accordion-content-height)'
  				},
  				to: {
  					height: '0'
  				}
  			},
  			'accordion-down': {
  				from: {
  					height: '0'
  				},
  				to: {
  					height: 'var(--radix-accordion-content-height)'
  				}
  			},
  			'accordion-up': {
  				from: {
  					height: 'var(--radix-accordion-content-height)'
  				},
  				to: {
  					height: '0'
  				}
  			}
  		},
  		animation: {
  			'accordion-down': 'accordion-down 0.2s ease-out',
  			'accordion-up': 'accordion-up 0.2s ease-out',
  			'accordion-down': 'accordion-down 0.2s ease-out',
  			'accordion-up': 'accordion-up 0.2s ease-out'
  		},
  		boxShadow: {
  			DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.02)',
  			md: '0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.02)',
  			lg: '0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01)',
  			xl: '0 20px 25px -5px rgba(0, 0, 0, 0.08), 0 10px 10px -5px rgba(0, 0, 0, 0.01)'
  		},
  		outline: {
  			blue: '2px solid rgba(0, 112, 244, 0.5)'
  		},
  		fontFamily: {
  			inter: ['var(--font-inter)', 'sans-serif']
  		},
  		fontSize: {
  			xs: ['0.75rem', { lineHeight: '1.5' }],
  			sm: ['0.875rem', { lineHeight: '1.5715' }],
  			base: ['1rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
  			lg: ['1.125rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
  			xl: ['1.25rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
  			'2xl': ['1.5rem', { lineHeight: '1.33', letterSpacing: '-0.01em' }],
  			'3xl': ['1.88rem', { lineHeight: '1.33', letterSpacing: '-0.01em' }],
  			'4xl': ['2.25rem', { lineHeight: '1.25', letterSpacing: '-0.02em' }],
  			'5xl': ['3rem', { lineHeight: '1.25', letterSpacing: '-0.02em' }],
  			'6xl': ['3.75rem', { lineHeight: '1.2', letterSpacing: '-0.02em' }]
  		},
  		screens: {
  			xs: '480px',
  			'2xs': '360px'
  		},
  		listStyleType: {
  			none: 'none',
  			disc: 'disc',
  			decimal: 'decimal',
  			square: 'square',
  			roman: 'upper-roman',
  			dash: ''
  		}
  	}
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    require('tailwindcss-animate'),
    // add custom variant for expanding sidebar
    plugin(({ addVariant, e }) => {
      addVariant('sidebar-expanded', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => `.sidebar-expanded .${e(`sidebar-expanded${separator}${className}`)}`);
      });
    }),    
  ],
}