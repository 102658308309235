  import Link from 'next/link'
  import Image from 'next/image'
  import PageNotFoundMeme from '@/public/images/pink_phanter_construction.jpg'

export function BugError() {
	return (
		<div className="relative bg-white dark:bg-slate-900 h-full w-full">
			<div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-[96rem] mx-auto">
				<div className="max-w-2xl m-auto mt-16">
					<div className="text-center px-4">
						<div className="inline-flex mb-8">
							<Image className="" src={PageNotFoundMeme} alt="Mary Kay meme ?" />
						</div>
						<div className="mb-6"><span className='italic font-bold'>OPSss... </span>Aconteceu um erro aqui! Já fomos avisados e estamos trabalhando nisso! </div>
						<Link href="/" className="btn bg-indigo-500 hover:bg-indigo-600 text-white">Voltar pra Home</Link>
					</div>
				</div>
			</div>
      	</div>
	)
}